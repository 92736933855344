'use client'

import { FavoriteButton } from 'product-card/components/shared/FavoriteButton'
import { PdpLink } from 'product-card/components/shared/PdpLink'
import { ColorPicker } from 'product-card/components/shared/ProductColor/ColorPicker'
import { ProductPrice } from 'product-card/components/shared/ProductPrice'
import { SizeSheetIcon } from 'product-card/components/shared/ProductSizes/SizeSheetIcon'
import { ProductTags } from 'product-card/components/shared/ProductTags'
import { ProductTitle } from 'product-card/components/shared/ProductTitle'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { ProductCardIcon } from 'product-card/types'
import type { Product } from 'product/types'
import type { ProductPrices } from 'product/types/Prices'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { every } from 'utils/every/every'
import { some } from 'utils/some/some'

import { useProductCardConsumerLayoutContext } from '../../../hooks/useProductCardConsumerLayoutContext'

import styles from './ProductDetails.module.scss'

interface ProductDetailsProps {
	readonly productId: string
	readonly product: Product
	readonly prices?: ProductPrices
	readonly showAsFeatured: boolean
	readonly showDiscount?: boolean
}

export const ProductDetails = ({
	productId,
	product,
	prices,
	showAsFeatured,
	showDiscount,
}: ProductDetailsProps) => {
	const { isLargeOrGreater } = useResponsive()
	const { icon } = useProductCardContext()
	const { hasColorPicker, hasProductTags, shouldRenderLinkLabels } =
		useProductCardConsumerLayoutContext()

	const renderTags = every(
		!!product.tags && product?.tags?.length > 0,
		hasProductTags
	)

	const withIcon = icon !== ProductCardIcon.None

	return (
		<div className={styles.productDetails}>
			<div className={styles.productInfo}>
				<div className={styles.heading}>
					<ProductTags
						productTags={product.tags}
						renderTags={renderTags}
						withIcon={withIcon}
					/>
					<ProductTitle
						productName={product.name}
						renderTags={renderTags}
						withIcon={withIcon}
						colors={product.colors}
					/>
				</div>
				{withIcon && (
					<div className={styles.primaryAction}>
						{icon === ProductCardIcon.SizeSheet && (
							<SizeSheetIcon product={product} />
						)}
						{icon === ProductCardIcon.Favorite && (
							<FavoriteButton product={product} productId={productId} />
						)}
					</div>
				)}
			</div>

			<ProductPrice
				productId={productId}
				prices={prices}
				showDiscount={showDiscount}
			/>

			{some(hasColorPicker, showAsFeatured) && (
				<>
					{every(showAsFeatured, isLargeOrGreater) ||
					every(hasColorPicker, !isLargeOrGreater, shouldRenderLinkLabels) ? (
						<PdpLink
							showAsFeatured={showAsFeatured}
							product={product}
							productColors={product.colors}
						/>
					) : (
						<ColorPicker
							showAsFeatured={showAsFeatured}
							productColors={product.colors}
						/>
					)}
				</>
			)}
		</div>
	)
}
