import {
	type ReactNode,
	createContext,
	useCallback,
	useMemo,
	useRef,
} from 'react'

// TODO - This is a temporary solution while improvements are made to the Sheet component
// This context is used to store the timeout reference for the color sheet through rereenders

type SetCustomTimeoutFunction = (callback: () => void, delay: number) => void

interface ColorSheetTimeoutContextProps {
	setCustomTimeout: SetCustomTimeoutFunction
}

interface ColorSheetTimeoutProviderProps {
	readonly children: ReactNode
}

export const ColorSheetTimeoutContext =
	createContext<ColorSheetTimeoutContextProps | null>(null)

export const ColorSheetTimeoutProvider = ({
	children,
}: ColorSheetTimeoutProviderProps) => {
	const timeoutRef = useRef<NodeJS.Timeout | null>(null)

	const setCustomTimeout: SetCustomTimeoutFunction = useCallback(
		(callback, delay) => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current)
			}

			timeoutRef.current = setTimeout(callback, delay)
		},
		[]
	)

	const values = useMemo(
		() => ({
			setCustomTimeout,
		}),
		[setCustomTimeout]
	)

	return (
		<ColorSheetTimeoutContext.Provider value={values}>
			{children}
		</ColorSheetTimeoutContext.Provider>
	)
}
