'use client'

import { type CSSProperties } from 'react'
import { cx } from 'utils/cx'

import { SlideshowArrows } from './SlideshowArrows/SlideshowArrows'
import { useSlideshow } from './hooks/useSlideshow'
import { type SlideshowProps } from './types/Slideshow'

import styles from './Slideshow.module.scss'

export const INITIAL_OFFSET = 0 // px
export const INITIAL_TRANSITION_DURATION = 0 // ms

export function Slideshow({
	children,
	className,
	dataTestId,
	hasDotsIndicator = true,
	hasInitialAnimation = false,
	hasPreload = false,
	isTouchable = true,
	hasLoop = true,
	labels = { previous: 'previous', next: 'next' },
	onSwipe,
}: Readonly<SlideshowProps>): JSX.Element {
	const {
		bulletIndex,
		items,
		onArrowClick,
		onNavigationMouseEnter,
		onTouchEnd,
		onTouchMove,
		onTouchStart,
		onTransitionEnd,
		preloadedItems,
		slideshowRef,
	} = useSlideshow({ children, hasPreload, hasLoop })

	return (
		<div
			className={cx(className, styles.slideshowWrapper)}
			data-testid={dataTestId}
		>
			{preloadedItems?.map((preloadItem) => (
				<span
					className={styles.preloadedItem}
					aria-hidden
					key={preloadItem.key}
				>
					{preloadItem}
				</span>
			))}
			<div
				onTransitionEnd={onTransitionEnd}
				onTouchStart={onTouchStart}
				onTouchMove={onTouchMove}
				onTouchEnd={() => {
					onSwipe?.()
					onTouchEnd()
				}}
				className={cx(
					styles.slideshow,
					hasInitialAnimation && items.length > 1 && styles.animated
				)}
				ref={slideshowRef}
				data-testid='slideshow'
				style={
					{
						'--slideshow-offset': `${INITIAL_OFFSET}px`,
						'--slideshow-transition-duration': `${INITIAL_TRANSITION_DURATION}ms`,
					} as CSSProperties
				}
			>
				{items}
			</div>
			<SlideshowArrows
				bulletIndex={bulletIndex}
				hasLoop={hasLoop}
				dataTestId={dataTestId}
				onArrowClick={onArrowClick}
				onNavigationMouseEnter={onNavigationMouseEnter}
				labels={labels}
				isTouchable={isTouchable}
				lastBulletIndex={children.length - 1}
				onSwipe={onSwipe}
			/>
			{hasDotsIndicator && (
				<div className={styles.bullets}>
					{children.map((child, index) => (
						<span
							className={cx(
								styles.bullet,
								index === bulletIndex && styles.active
							)}
							key={child.key}
							data-testid='slideshow.bullet'
						/>
					))}
				</div>
			)}
		</div>
	)
}
