'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { type TouchEvent, useRef } from 'react'

import { SlideshowFromDirection } from '../../types/Slideshow'
import {
	type UseSlideshowTouch,
	type UseSlideshowTouchProps,
} from '../../types/useSlideshowTouch.types'

const MIN_FRACTION_TO_SLIDE = 4
const SCROLLING_FACTOR = 1.1
const NO_LOOP_MARGIN = 2

export const useSlideshowTouch = ({
	beforeSlideOffsetRef,
	activeItemIndexRef,
	hasLoop,
	sliderItemsQuantity,
	updateStyles,
	handleLoop,
	isOutOfBounds,
	restoreSlide,
	handleSlide,
	updateBulletsByDirection,
	preloadNeighborItems,
	hasPreload,
	getNextItemToPreload,
	bulletIndex,
	setPreloadedItems,
	itemWidthRef,
}: UseSlideshowTouchProps): UseSlideshowTouch => {
	const {
		country: { isRtl },
	} = useMasterData()
	/* Touch start position in order to calculate the slide distance */
	const touchStartRef = useRef<number | null>(null)
	/* Touch end position in order to calculate the slide distance */
	const touchEndRef = useRef<number | null>(null)
	/* To prevent images slide on scroll user action */
	const verticalOffsetRef = useRef<number>(0)

	const getPreviousNextSlide = (
		isLeftToRightGesture: boolean,
		isRightToLeftGesture: boolean
	) => {
		return {
			toPreviousSlide:
				(!isRtl && isLeftToRightGesture) || (isRtl && isRightToLeftGesture),
			toNextSlide:
				(!isRtl && isRightToLeftGesture) || (isRtl && isLeftToRightGesture),
		}
	}

	const handleTouchPreload = () => {
		if (hasPreload) {
			const previousItem =
				!hasLoop && activeItemIndexRef.current === 0
					? undefined
					: getNextItemToPreload(bulletIndex, SlideshowFromDirection.LEFT)

			const nextItem = getNextItemToPreload(
				bulletIndex,
				SlideshowFromDirection.RIGHT
			)

			setPreloadedItems([...(previousItem ? [previousItem] : []), nextItem])
		}
	}

	const onTouchStart = (touchEvent: TouchEvent<HTMLDivElement>): void => {
		touchStartRef.current = touchEvent.targetTouches[0].clientX
		verticalOffsetRef.current = touchEvent.targetTouches[0].clientY
		touchEndRef.current = null
		handleTouchPreload()
	}

	const onTouchMove = (touchEvent: TouchEvent<HTMLDivElement>): void => {
		if (touchStartRef.current === null) {
			return
		}

		touchEndRef.current = touchEvent.targetTouches[0].clientX
		const currentY = touchEvent.targetTouches[0].clientY
		const verticalDiff = Math.abs(verticalOffsetRef.current - currentY)
		const offsetToAdd = touchEndRef.current - touchStartRef.current

		if (verticalDiff > Math.abs(offsetToAdd) * SCROLLING_FACTOR) {
			return
		}

		const isFirstItemActive = activeItemIndexRef.current === 0
		const isLastItemActive =
			activeItemIndexRef.current === sliderItemsQuantity - 1
		const toPreviousSlide = isRtl ? offsetToAdd < 0 : offsetToAdd > 0
		let newOffset = beforeSlideOffsetRef.current + offsetToAdd

		if (!hasLoop) {
			newOffset = calculateNewOffset(
				isFirstItemActive,
				isLastItemActive,
				toPreviousSlide,
				offsetToAdd
			)
		}

		updateStyles({ offset: newOffset, transitionDuration: 0 })

		if (
			isOutOfBounds({
				hasLoop,
				isFirstItemActive,
				isLastItemActive,
				newOffset,
			})
		) {
			return
		}

		if (isFirstItemActive && toPreviousSlide) {
			handleLoop(SlideshowFromDirection.LEFT)
		}

		if (isLastItemActive && !toPreviousSlide) {
			handleLoop(SlideshowFromDirection.RIGHT)
		}
	}

	const computeGestureDirection = (distance: number) => {
		const minDistance = itemWidthRef.current / MIN_FRACTION_TO_SLIDE
		const isLeftToRightGesture = distance > minDistance
		const isRightToLeftGesture = distance < -minDistance
		const { toPreviousSlide, toNextSlide } = getPreviousNextSlide(
			isLeftToRightGesture,
			isRightToLeftGesture
		)
		let fromDirection: SlideshowFromDirection

		if (isRtl) {
			fromDirection =
				distance > 0
					? SlideshowFromDirection.RIGHT
					: SlideshowFromDirection.LEFT
		} else {
			fromDirection =
				distance > 0
					? SlideshowFromDirection.LEFT
					: SlideshowFromDirection.RIGHT
		}

		return { fromDirection, toPreviousSlide, toNextSlide }
	}

	const onTouchEnd = (): void => {
		if (touchStartRef.current === null || touchEndRef.current === null) {
			return
		}
		const distance = touchEndRef.current - touchStartRef.current
		const { fromDirection, toPreviousSlide, toNextSlide } =
			computeGestureDirection(distance)
		const isFirstItemActive = activeItemIndexRef.current === 0
		const isLastItemActive =
			activeItemIndexRef.current === sliderItemsQuantity - 1
		const shouldPreventSlide =
			!hasLoop &&
			isAtBoundary(
				isFirstItemActive,
				isLastItemActive,
				toPreviousSlide,
				toNextSlide
			)
		if (shouldPreventSlide) {
			restoreSlide()
			return
		}
		if (toPreviousSlide || toNextSlide) {
			handleSlide(toPreviousSlide)
			const newBulletIndex = updateBulletsByDirection(fromDirection)
			preloadNeighborItems(newBulletIndex, fromDirection)
		} else {
			restoreSlide()
		}
	}

	const calculateNewOffset = (
		isFirstItemActive: boolean,
		isLastItemActive: boolean,
		toPreviousSlide: boolean,
		offsetToAdd: number
	): number => {
		if (isFirstItemActive && toPreviousSlide) {
			return isRtl
				? Math.max(-NO_LOOP_MARGIN, offsetToAdd)
				: Math.min(NO_LOOP_MARGIN, offsetToAdd)
		}

		if (isLastItemActive && !toPreviousSlide) {
			return isRtl
				? Math.min(
						beforeSlideOffsetRef.current + NO_LOOP_MARGIN,
						beforeSlideOffsetRef.current + offsetToAdd
					)
				: Math.max(
						beforeSlideOffsetRef.current - NO_LOOP_MARGIN,
						beforeSlideOffsetRef.current + offsetToAdd
					)
		}

		return beforeSlideOffsetRef.current + offsetToAdd
	}

	const isAtBoundary = (
		isFirstItemActive: boolean,
		isLastItemActive: boolean,
		toPreviousSlide: boolean,
		toNextSlide: boolean
	): boolean => {
		const isFirstAndGoingBack = isFirstItemActive && toPreviousSlide
		const isLastAndGoingForward = isLastItemActive && toNextSlide
		return isFirstAndGoingBack || isLastAndGoingForward
	}

	return {
		onTouchStart,
		onTouchMove,
		onTouchEnd,
	}
}
