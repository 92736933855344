import type { ProductType } from 'cart/hooks/mutations/useAddToCart'
import { useCartController } from 'cart/hooks/useCartController'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { dataTestIds } from 'product-card/constants/dataTestIds'
import { useProductCardConsumerLayoutContext } from 'product-card/hooks/useProductCardConsumerLayoutContext'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { PRODUCT_FULLSIZE_INFO_AVAILABILITY_KEY } from 'product/constants/constants'
import type { ProductFullSizeInfo, ProductSize } from 'product/types/Product'
import { useCallback } from 'react'
import type { PlpDomainData } from 'types/masterData/domainData/domainData'

import { appsAddToBag } from '../../../../../../utils/appsAddToBag/appsAddToBag'

/* eslint react-hooks/exhaustive-deps: warn */
interface AddButtonProps {
	readonly size: ProductFullSizeInfo | ProductSize
	readonly isSizeLabel?: boolean
	readonly className?: string
	readonly children?: React.ReactNode
	readonly onClose?: () => void
}

// TODO: remove this when we send the seller in the add to bag request
const PRODUCT_SELLER = 'mango'

export const AddButton = ({
	size,
	isSizeLabel = true,
	className,
	children,
	onClose,
}: AddButtonProps) => {
	const { isApp, custom } = useMasterData()
	const {
		productId,
		selectedColorId,
		lookId,
		dataTestId,
		location,
		productListId,
		onAddToCart,
	} = useProductCardContext()
	const { add, addWithoutNotification } = useCartController()
	const { layoutView, feedbackTypeOnAdd } =
		useProductCardConsumerLayoutContext()

	const isProductFullSizeInfo = PRODUCT_FULLSIZE_INFO_AVAILABILITY_KEY in size
	const isLastUnits = isProductFullSizeInfo && !!size.lastUnits

	const sizeDataTestId =
		dataTestIds[
			isProductFullSizeInfo && size.available
				? 'productCardSizeAvailable'
				: 'productCardSizeUnavailable'
		](dataTestId)

	const handleOnClick = useCallback(() => {
		const productType: ProductType = {
			lookId,
			layoutView,
			productId,
			sizeId: size.id,
			seller: PRODUCT_SELLER,
			colorId: selectedColorId,
			lastUnits: isLastUnits,
		}

		if (feedbackTypeOnAdd === 'none') {
			addWithoutNotification(productType, location, productListId, true)
		} else if (isApp) {
			appsAddToBag(productId, selectedColorId, lookId, custom as PlpDomainData)
		} else {
			add(productType, location, productListId)
		}

		if (onAddToCart) {
			onAddToCart()
		}

		if (onClose) {
			onClose()
		}
	}, [
		add,
		addWithoutNotification,
		feedbackTypeOnAdd,
		isLastUnits,
		layoutView,
		location,
		lookId,
		onAddToCart,
		onClose,
		productId,
		productListId,
		selectedColorId,
		size.id,
	])

	return (
		<button
			className={className}
			onClick={handleOnClick}
			data-testid={sizeDataTestId}
		>
			{isSizeLabel && size.label}
			{children}
		</button>
	)
}
