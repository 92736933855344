'use client'

import { dataTestIds } from 'product-card/constants/dataTestIds'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import type { Product } from 'product/types/Product'
import { applyStylesIf, cx } from 'utils/cx'

import styles from './ProductTags.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface ProductTagsProps {
	readonly productTags: Product['tags']
	readonly renderTags: boolean
	readonly withIcon: boolean
}

const NEW_PRODUCT_TAG = 'New Now'

export const ProductTags = ({
	productTags,
	renderTags: showTags,
	withIcon,
}: ProductTagsProps) => {
	const { dataTestId } = useProductCardContext()

	if (showTags && productTags) {
		const isNewNowTag = productTags?.includes(NEW_PRODUCT_TAG)
			? dataTestIds.productCardNewNowTag(dataTestId)
			: dataTestIds.productCardTag(dataTestId)

		return (
			<p
				className={cx(
					text.uppercaseXS,
					styles.productTags,
					applyStylesIf(withIcon, styles.withIcon)
				)}
				data-testid={isNewNowTag}
			>
				{productTags.join(' - ')}
			</p>
		)
	}

	return null
}
