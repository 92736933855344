'use client'

import { type ReactElement, cloneElement, useRef, useState } from 'react'

import {
	SlideshowFromDirection,
	type SlideshowIndexDirectionsMap,
	type UseSlideshow,
	type UseSlideshowProps,
} from '../types/Slideshow'
import { useSlideshowBullets } from './useSlideshowBulltes/useSlideshowBullets'
import { useSlideshowNavigation } from './useSlideshowNavigation/useSlideshowNavigation'
import { useSlideshowTouch } from './useSlideshowTouch/useSlideshowTouch'
import { useSlideshowUtils } from './useSlideshowUtils'

const addTestIdToChildren = (children: ReactElement[]): ReactElement[] => {
	return children.map((child, idx) => {
		if (!child.props['data-testid']) {
			return cloneElement(child, {
				'data-testid': `slideshow-item-${idx}`,
			})
		}
		return child
	})
}

export function useSlideshow({
	children,
	hasPreload,
	hasLoop,
}: UseSlideshowProps): UseSlideshow {
	/* Array of items in order to preload them in the DOM */
	const [preloadedItems, setPreloadedItems] = useState<
		JSX.Element[] | undefined
	>(undefined)
	/* Slideshow element in order to change its styles and know its width so we know how much offset is needed to apply */
	const slideshowRef = useRef<HTMLDivElement | null>(null)
	/* Current slide offset, so we are able to restore the slide in case of not changing to the next one, and be able to calculate new offsets */
	const beforeSlideOffsetRef = useRef(0)
	/* Item width, so we know how much we must translate the slideshow on each slide */
	const itemWidthRef = useRef(0)
	/* Item DOM index the user is seeing, so we know if a loop must be applied */
	const activeItemIndexRef = useRef(0)

	function getNewIndexFromDirection(
		currentIndex: number,
		fromDirection: SlideshowFromDirection
	): number {
		const newIndexFromDirections: SlideshowIndexDirectionsMap = {
			[SlideshowFromDirection.LEFT]:
				currentIndex <= 0 ? children.length - 1 : currentIndex - 1,
			[SlideshowFromDirection.RIGHT]:
				currentIndex >= children.length - 1 ? 0 : currentIndex + 1,
		}

		return newIndexFromDirections[fromDirection]
	}

	const { bulletIndex, updateBulletsByDirection } = useSlideshowBullets({
		activeItemIndexRef,
		getNewIndexFromDirection,
	})

	const {
		getNextItemToPreload,
		handleLoop,
		handleSlide,
		restoreSlide,
		updateStyles,
		getRtlDirection,
		isOutOfBounds,
		preloadNeighborItems,
		onTransitionEnd,
	} = useSlideshowUtils({
		activeItemIndexRef,
		beforeSlideOffsetRef,
		children,
		itemWidthRef,
		slideshowRef,
		hasPreload,
		setPreloadedItems,
		getNewIndexFromDirection,
	})

	const { onTouchEnd, onTouchMove, onTouchStart } = useSlideshowTouch({
		sliderItemsQuantity: children.length,
		activeItemIndexRef,
		beforeSlideOffsetRef,
		bulletIndex,
		hasLoop,
		hasPreload,
		itemWidthRef,
		getNextItemToPreload,
		handleLoop,
		handleSlide,
		isOutOfBounds,
		preloadNeighborItems,
		restoreSlide,
		setPreloadedItems,
		updateBulletsByDirection,
		updateStyles,
	})

	const { onArrowClick, onNavigation, onNavigationMouseEnter } =
		useSlideshowNavigation({
			sliderItemsQuantity: children.length,
			activeItemIndexRef,
			bulletIndex,
			slideshowRef,
			getNewIndexFromDirection,
			getNextItemToPreload,
			getRtlDirection,
			handleLoop,
			handleSlide,
			preloadNeighborItems,
			setPreloadedItems,
			updateBulletsByDirection,
		})

	return {
		bulletIndex,
		onArrowClick,
		onNavigation,
		onNavigationMouseEnter,
		onTouchStart,
		onTouchMove,
		onTouchEnd,
		onTransitionEnd,
		preloadedItems,
		slideshowRef,
		activeItemIndexRef,
		items: addTestIdToChildren(children),
	}
}
