import {
	type ReactNode,
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'

interface ProductCardHoverContextProps {
	isHovered: boolean
	handleMouseEnter: () => void
	handleMouseLeave: () => void
}

interface ProductCardHoverProviderProps {
	readonly children: ReactNode
}

export const ProductCardHoverContext =
	createContext<ProductCardHoverContextProps | null>(null)

export const ProductCardHoverProvider = ({
	children,
}: ProductCardHoverProviderProps) => {
	const [isHovered, setIsHovered] = useState<boolean>(false)

	const handleMouseEnter = useCallback(() => {
		setIsHovered(true)
	}, [])

	const handleMouseLeave = useCallback(() => {
		setIsHovered(false)
	}, [])

	useEffect(() => {
		// When the user is on a touch device, we assume that the user is hovering
		setIsHovered(window.matchMedia('(pointer: coarse)').matches)
	}, [])

	const values = useMemo(
		() => ({
			isHovered,
			handleMouseEnter,
			handleMouseLeave,
		}),
		[isHovered, handleMouseEnter, handleMouseLeave]
	)

	return (
		<ProductCardHoverContext.Provider value={values}>
			{children}
		</ProductCardHoverContext.Provider>
	)
}
