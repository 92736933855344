import { useBackInStockModalContext } from 'back-in-stock/src/hooks/useBackInStockModalContext/useBackInStockModalContext'
import { dataTestIds } from 'product-card/constants/dataTestIds'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { useProductColors } from 'product-card/hooks/useProductColors'
import type { Product, ProductFullSizeInfo } from 'product/types/Product'

import styles from './BackInStock.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface AddButtonProps {
	readonly product: Product
	readonly size: ProductFullSizeInfo
	readonly className?: string
	readonly isSizeLabel?: boolean
	readonly onClose?: () => void
	readonly children?: React.ReactNode
}

export const BackInStockButton = ({
	product,
	size,
	className,
	isSizeLabel = true,
	onClose,
	children,
}: AddButtonProps) => {
	const { productId, dataTestId } = useProductCardContext()
	const { selectedColor } = useProductColors({ product })
	const { openProductSubscription } = useBackInStockModalContext()

	const sizeDataTestId =
		dataTestIds[
			size.available ? 'productCardSizeAvailable' : 'productCardSizeUnavailable'
		](dataTestId)

	const handleOnClick = () => {
		if (selectedColor) {
			openProductSubscription({
				productId,
				productSizeId: size.id,
				productName: product.name,
				productColorId: selectedColor.id,
				feedbackDelay: 0,
			})
		}

		if (onClose) {
			onClose()
		}
	}

	return (
		<button
			className={`${text.bodyL} ${styles.disabled} ${className}`}
			onClick={handleOnClick}
			data-testid={sizeDataTestId}
		>
			{isSizeLabel && size.label}
			{children}
		</button>
	)
}
