'use client'

import { ClientProductPrice } from 'fukku/Price/client/ClientProductPrice'
import type { PriceClasses } from 'fukku/Price/types'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import type { ProductPrices } from 'product/types/Prices'

import { useProductCardConsumerLayoutContext } from '../../../hooks/useProductCardConsumerLayoutContext'

import text from 'fukku/styles/texts.module.scss'

interface ProductPriceProps {
	readonly productId: string
	readonly prices?: ProductPrices
	readonly showDiscount?: boolean
}

export const ProductPrice = ({
	productId,
	prices,
	showDiscount,
}: ProductPriceProps) => {
	const { selectedColorId } = useProductCardContext()
	const { showPriceHistory } = useProductCardConsumerLayoutContext()

	const priceClasses: PriceClasses = {
		wrapper: text.bodyM,
		price: text.bodyM,
		firstCrossedOutPrice: text.bodyM,
		crossedOutPrices: text.bodyM,
		discountRate: text.bodyM,
		additionalPrice: text.bodyM,
		additionalFirstCrossedOutPrice: text.bodyM,
		additionalCrossedOutPrices: text.bodyM,
	}

	return (
		selectedColorId && (
			<ClientProductPrice
				classes={priceClasses}
				product={productId}
				color={selectedColorId}
				prices={prices}
				horizontal
				reverse={false}
				align='center'
				additionalPriceVertical
				hidePriceHistory={!showPriceHistory}
				showDiscount={showDiscount}
			/>
		)
	)
}
