'use client'

import { OverlayContent } from 'product-card/components/shared/OverlayContent'
import { ProductImage } from 'product-card/components/shared/ProductImage'
import { useProductCardConsumerLayoutContext } from 'product-card/hooks/useProductCardConsumerLayoutContext'
import { useProductCardHoverContext } from 'product-card/hooks/useProductCardHoverContext'
import { ProductCardVariant } from 'product-card/types'
import type { Product, ProductImageType } from 'product/types'
import { cx } from 'utils/cx'

import styles from './ProductImageContainer.module.scss'

interface ProductImageContainerProps {
	readonly productId: string
	readonly product: Product
	readonly imageSortCriteria: ProductImageType[]
	readonly priority?: boolean
	readonly showAsFeatured?: boolean
}

export const ProductImageContainer = ({
	productId,
	product,
	imageSortCriteria,
	priority,
	showAsFeatured,
}: ProductImageContainerProps) => {
	const { handleMouseEnter } = useProductCardHoverContext()
	const { variant } = useProductCardConsumerLayoutContext()

	return (
		<div
			role='presentation'
			className={cx(
				styles.productImageContainer,
				showAsFeatured && styles.featured
			)}
			onMouseEnter={handleMouseEnter}
		>
			<ProductImage
				product={product}
				imageSortCriteria={imageSortCriteria}
				priority={priority}
				showAsFeatured={showAsFeatured}
				variant={variant}
			/>
			{variant === ProductCardVariant.General && (
				<OverlayContent
					productId={productId}
					product={product}
					className={styles.overlayContainer}
				/>
			)}
		</div>
	)
}
