'use client'

import { ProductCardIcon, type ProductCardProps } from 'product-card/types'
import type { ProductColor } from 'product/types'
import type { ProductLocations } from 'product/types/ProductLocations'
import {
	type ReactElement,
	createContext,
	useMemo,
	useRef,
	useState,
} from 'react'

export interface ProductCardContextProps {
	productId: string
	initialProductId: string
	setProductId: (productId: string) => void
	dataTestId: string
	lookId: string
	defaultColorId: string
	selectedColorId: ProductColor['id']
	setSelectedColorId: (colorId: string) => void
	location: ProductLocations
	icon: ProductCardIcon
	setIcon: (icon: ProductCardIcon) => void
	productListId?: string
	draggable?: boolean
	/** On add to cart callback */
	onAddToCart?: () => void
}

interface ProductCardProviderProps {
	readonly initialProductId: string
	readonly initialLookId: string
	readonly initialDataTestId: string
	readonly initialColorId: string
	readonly location: ProductLocations
	readonly children: ReactElement<ProductCardProps>
	readonly productListId?: string
	readonly noDraggable?: boolean
	/** On add to cart callback */
	readonly onAddToCart?: () => void
}

export const ProductCardContext = createContext<ProductCardContextProps | null>(
	null
)

export const ProductCardProvider = ({
	children,
	initialProductId,
	initialDataTestId,
	initialLookId,
	initialColorId,
	location,
	productListId,
	noDraggable,
	onAddToCart,
}: ProductCardProviderProps) => {
	const [productId, setProductId] = useState<string>(initialProductId)
	const dataTestId = useRef<string>(initialDataTestId).current
	const lookId = useRef<string>(initialLookId).current
	const defaultColorId = useRef<string>(initialColorId).current
	const [selectedColorId, setSelectedColorId] = useState<string>(initialColorId)
	const [icon, setIcon] = useState<ProductCardIcon>(ProductCardIcon.None)

	const values = useMemo<ProductCardContextProps>(
		() => ({
			productId,
			initialProductId,
			setProductId,
			dataTestId,
			lookId,
			defaultColorId,
			selectedColorId,
			setSelectedColorId,
			location,
			productListId,
			icon,
			setIcon,
			onAddToCart,
			draggable: !noDraggable,
		}),
		[
			initialProductId,
			productId,
			setProductId,
			dataTestId,
			lookId,
			selectedColorId,
			setSelectedColorId,
			location,
			productListId,
			icon,
			setIcon,
			noDraggable,
			onAddToCart,
		]
	)

	return (
		<ProductCardContext.Provider value={values}>
			{children}
		</ProductCardContext.Provider>
	)
}
