import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import type { MouseEvent } from 'react'

import type {
	SlideshowFromDirection,
	UseSlideshowArrows,
	UseSlideshowArrowsProps,
} from '../../types/Slideshow'

export const useSlideshowArrows = ({
	bulletIndex,
	lastBulletIndex,
	hasLoop,
	onSwipe,
	onArrowClick,
	isTouchable,
	onNavigationMouseEnter,
}: UseSlideshowArrowsProps): UseSlideshowArrows => {
	const {
		country: { isRtl },
	} = useMasterData()

	const isAtStart = bulletIndex === 0
	const isAtEnd = bulletIndex === lastBulletIndex
	const isArrowPreviousDisabled = !hasLoop && (isRtl ? isAtEnd : isAtStart)
	const isArrowNextDisabled = !hasLoop && (isRtl ? isAtStart : isAtEnd)

	const handleClick = (
		e: MouseEvent<HTMLButtonElement>,
		direction: SlideshowFromDirection
	) => {
		onSwipe?.()
		onArrowClick(e, direction)
	}

	const handleMouseEnter = (direction: SlideshowFromDirection) => {
		if (!isTouchable) {
			onNavigationMouseEnter(direction)
		}
	}

	return {
		isArrowPreviousDisabled,
		isArrowNextDisabled,
		handleClick,
		handleMouseEnter,
	}
}
