import { ProductCardAnimation } from 'product-card/types'
import { type ReactNode, createContext, useMemo } from 'react'

export interface ProductCardAnimationContextProps {
	animation: ProductCardAnimation
}

interface ProductCardAnimationProviderProps {
	readonly animation: ProductCardAnimation
	readonly children: ReactNode
}

const defaultContext: ProductCardAnimationContextProps = {
	animation: ProductCardAnimation.FadeIn,
}

export const ProductCardAnimationContext = createContext(defaultContext)

export const ProductCardAnimationProvider = ({
	animation,
	children,
}: ProductCardAnimationProviderProps) => {
	const values = useMemo(() => ({ animation }), [animation])
	return (
		<ProductCardAnimationContext.Provider value={values}>
			{children}
		</ProductCardAnimationContext.Provider>
	)
}
