import type { ProductType } from 'cart/hooks/mutations/useAddToCart'
import { useCartController } from 'cart/hooks/useCartController'
import { ButtonPrimary } from 'fukku/Button/Primary'
import { ButtonSecondary } from 'fukku/Button/Secondary'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useProductCardConsumerLayoutContext } from 'product-card/hooks/useProductCardConsumerLayoutContext'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import type { ProductSize } from 'product/types/Product'
import type { PlpDomainData } from 'types/masterData/domainData/domainData'

import { appsAddToBag } from '../../../../../utils/appsAddToBag/appsAddToBag'

import fukkuStyles from 'fukku/styles/classes.module.scss'
import text from 'fukku/styles/texts.module.scss'
import styles from 'product-card/components/shared/ProductSizes/AddToBag/AddToBagOneSizeButton/AddToBagOneSizeButton.module.scss'

interface AddToBagOneSizeButtonProps {
	readonly size: ProductSize
	readonly isOverlay?: boolean
	readonly lastUnits?: boolean
}

// TODO: remove this when we send the seller in the add to bag request
const PRODUCT_SELLER = 'mango'
const SHOWSIZE_BUTTON_LABEL = 'productCard.showSizes.button'
const DATA_TEST_ID = 'productCard.oneSizeButton'

export const AddToBagOneSizeButton = ({
	size,
	isOverlay = false,
	lastUnits = false,
}: AddToBagOneSizeButtonProps) => {
	const { isApp, custom } = useMasterData()
	const { t } = useLabels()
	const { layoutView } = useProductCardConsumerLayoutContext()
	const {
		productId,
		selectedColorId,
		lookId,
		location,
		productListId,
		onAddToCart,
	} = useProductCardContext()
	const { add, addWithoutNotification } = useCartController()
	const { shouldRenderAddButtonAsPrimary, feedbackTypeOnAdd } =
		useProductCardConsumerLayoutContext()

	const onAdd = () => {
		const productType: ProductType = {
			productId,
			lookId,
			lastUnits,
			layoutView,
			sizeId: size.id,
			seller: PRODUCT_SELLER,
			colorId: selectedColorId,
		}

		if (feedbackTypeOnAdd === 'none') {
			addWithoutNotification(productType, location, productListId, true)
		} else if (isApp) {
			appsAddToBag(productId, selectedColorId, lookId, custom as PlpDomainData)
		} else {
			add(productType, location, productListId)
		}

		if (onAddToCart) {
			onAddToCart()
		}
	}

	if (isOverlay) {
		return (
			<>
				<button
					type='button'
					className={`${text.bodyMRegular} ${styles.overlayButton}`}
					onClick={onAdd}
					data-testid={DATA_TEST_ID}
				>
					{t(SHOWSIZE_BUTTON_LABEL)}
				</button>
				<span className={fukkuStyles.srOnly}>
					{t('product.general.addSize.selector.title')}
				</span>
			</>
		)
	}

	const ButtonComponent = shouldRenderAddButtonAsPrimary
		? ButtonPrimary
		: ButtonSecondary

	return (
		<ButtonComponent
			fullWidth
			className={text.titleM}
			onClick={onAdd}
			data-testid={DATA_TEST_ID}
		>
			{t(SHOWSIZE_BUTTON_LABEL)}
		</ButtonComponent>
	)
}
