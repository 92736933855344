'use client'

import Image from 'next/image'
import { dataTestIds } from 'product-card/constants/dataTestIds'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { reorderColorsById } from 'product-card/utils/reorderColorsById'
import { sendChangeColorEvent } from 'product/analytics/sendChangeColorEvent'
import type { ProductColor } from 'product/types'
import { GenericEvents as ProductGenericEvents } from 'product/types/GenericEvents'
import { cx } from 'utils/cx'

import styles from './ColorPicker.module.scss'

interface ColorPickerProps {
	readonly productColors: ProductColor[]
	readonly showAsFeatured: boolean
}
interface HandleClickProps {
	colorId: string
	relatedProductId: string | undefined
}

export const ColorPicker = ({
	productColors,
	showAsFeatured,
}: ColorPickerProps) => {
	const {
		defaultColorId,
		selectedColorId,
		setSelectedColorId,
		productId,
		setProductId,
		location,
		initialProductId,
	} = useProductCardContext()

	const orderColorPickerInfo = reorderColorsById({
		productColors,
		defaultColor: defaultColorId,
	})

	const shouldRenderColorPicker =
		!showAsFeatured && orderColorPickerInfo.length > 1

	const isSelectedColor = (colorId: string) => colorId === selectedColorId

	const handleClick = ({ colorId, relatedProductId }: HandleClickProps) => {
		setSelectedColorId(colorId)
		sendChangeColorEvent({
			eventName: ProductGenericEvents.PLP_ACTION,
			productId,
			colorId,
			location,
		})
		// If no related product is provided for this color, we restore the reference with which the card was initially loaded
		if (setProductId) {
			setProductId(relatedProductId ?? initialProductId)
		}
	}

	if (shouldRenderColorPicker) {
		return (
			<div className={styles.colorPicker} data-testid='productCard.colorPicker'>
				{orderColorPickerInfo.map((color) => (
					<button
						key={`${color.colorId}/${productId}`}
						className={cx(
							styles.colorButton,
							isSelectedColor(color.colorId) && styles.selected
						)}
						data-testid={dataTestIds.productCardColorButton(color.colorId)}
						onClick={() =>
							handleClick({
								colorId: color.colorId,
								relatedProductId: color.relatedProductId,
							})
						}
					>
						<span>
							<Image
								alt={color.colorId}
								src={color.bulletImg}
								width={14}
								height={14}
								sizes='14px'
							/>
						</span>
					</button>
				))}
			</div>
		)
	}

	return null
}
