'use client'

import { type MouseEvent } from 'react'

import { SlideshowFromDirection } from '../../types/Slideshow'
import { type UseSlideshowNavigationProps } from '../../types/useSlideshowNavigation.types'

export const useSlideshowNavigation = ({
	slideshowRef,
	activeItemIndexRef,
	sliderItemsQuantity,
	handleLoop,
	handleSlide,
	updateBulletsByDirection,
	getNextItemToPreload,
	getRtlDirection,
	getNewIndexFromDirection,
	bulletIndex,
	setPreloadedItems,
	preloadNeighborItems,
}: UseSlideshowNavigationProps) => {
	const onArrowClick = (
		event: MouseEvent<HTMLButtonElement>,
		direction: SlideshowFromDirection
	): void => {
		event.preventDefault()
		event.stopPropagation()
		const rtlDirection = getRtlDirection(direction)
		const newIndex = getNewIndexFromDirection(bulletIndex, rtlDirection)
		setPreloadedItems([getNextItemToPreload(newIndex, rtlDirection)])
		onNavigation(rtlDirection)
	}

	const onNavigationMouseEnter = (
		fromDirection: SlideshowFromDirection
	): void => {
		preloadNeighborItems(bulletIndex, getRtlDirection(fromDirection))
	}

	const onNavigation = (fromDirection: SlideshowFromDirection): void => {
		const onGoingSlideChange =
			slideshowRef.current?.style.getPropertyValue(
				'--slideshow-transition-duration'
			) !== '0ms'
		const toPreviousSlide = fromDirection === SlideshowFromDirection.LEFT
		const isFirstItemActive = activeItemIndexRef.current === 0
		const isLastItemActive =
			activeItemIndexRef.current === sliderItemsQuantity - 1
		if (onGoingSlideChange) {
			return
		}
		if (toPreviousSlide && isFirstItemActive) {
			handleLoop(fromDirection, true)
		} else if (!toPreviousSlide && isLastItemActive) {
			handleLoop(fromDirection, true)
		} else {
			handleSlide(toPreviousSlide)
		}
		updateBulletsByDirection(fromDirection)
	}

	return {
		onArrowClick,
		onNavigationMouseEnter,
		onNavigation,
	}
}
