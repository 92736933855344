import type { ProductBase } from 'product/types/Product'
import type { PlpDomainData } from 'types/masterData/domainData/domainData'
import { Events } from 'webview/constants/events'
import { sendAppEvent } from 'webview/utils/sendAppEvent'

export const appsAddToBag = (
	productId: string,
	selectedColorId: string,
	lookId: string,
	custom?: PlpDomainData
) => {
	const analyticsListName = custom?.item_list_name

	const optionalParams: ProductBase = {
		id: productId,
		colorId: selectedColorId,
		lookId,
		analyticsListName,
	}

	sendAppEvent({ event: Events.AddToCart, optionalParams })
}
