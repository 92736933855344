'use client'

import {
	type MutableRefObject,
	type ReactNode,
	createContext,
	useMemo,
} from 'react'

interface ProductCardRefContextProps {
	productCardRef: MutableRefObject<HTMLDivElement | null>
}

interface ProductCardRefProviderProps {
	readonly initialProductCardRef: MutableRefObject<HTMLDivElement | null>
	readonly children: ReactNode
}

export const ProductCardRefContext =
	createContext<ProductCardRefContextProps | null>(null)

export const ProductCardRefProvider = ({
	children,
	initialProductCardRef,
}: ProductCardRefProviderProps) => {
	const values = useMemo(
		() => ({
			productCardRef: initialProductCardRef,
		}),
		[initialProductCardRef]
	)

	return (
		<ProductCardRefContext.Provider value={values}>
			{children}
		</ProductCardRefContext.Provider>
	)
}
