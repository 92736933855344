'use client'

import { ColorSheetTimeoutProvider } from 'product-card/context/ColorSheetTimeoutContext'
import type { ProductColor } from 'product/types/Product'

import { ColorSheetBody } from './ColorSheetBody'

interface ColorSheetProps {
	readonly productColors: ProductColor[]
	readonly onClose: () => void
}

export const ColorSheet = ({ productColors, onClose }: ColorSheetProps) => {
	if (productColors) {
		return (
			<ColorSheetTimeoutProvider>
				<ColorSheetBody productColors={productColors} onClose={onClose} />
			</ColorSheetTimeoutProvider>
		)
	}

	return null
}
