'use client'

import type { Product } from 'product/types'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { useProductCardConsumerLayoutContext } from '../../../hooks/useProductCardConsumerLayoutContext'
import { SizePicker } from '../ProductSizes/SizePicker'
import { OverlayLabel } from './OverlayLabel'

interface OverlayContentProps {
	readonly productId: string
	readonly product: Product
	readonly className: string
}

export const OverlayContent = ({
	productId,
	product,
	className,
}: OverlayContentProps) => {
	const { isTouchable } = useResponsive()
	const { hasProductLabels, hasSizePicker } =
		useProductCardConsumerLayoutContext()

	return (
		<div className={className}>
			{hasProductLabels && <OverlayLabel product={product} />}
			{hasSizePicker && !isTouchable && (
				<SizePicker productId={productId} product={product} />
			)}
		</div>
	)
}
