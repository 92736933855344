import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect } from 'react'

import {
	SlideshowFromDirection,
	type UpdateSlideshowStylesProps,
	type UseSlideshowUtils,
	type UseSlideshowUtilsProps,
} from '../types/Slideshow'

const TRANSITION_DURATION = 300 // ms
const SECOND_LAST_INDEX = 2

export function useSlideshowUtils({
	activeItemIndexRef,
	beforeSlideOffsetRef,
	getNewIndexFromDirection,
	children,
	itemWidthRef,
	slideshowRef,
	hasPreload,
	setPreloadedItems,
}: UseSlideshowUtilsProps): UseSlideshowUtils {
	const {
		country: { isRtl },
	} = useMasterData()

	const preloadNeighborItems = (
		index: number,
		direction: SlideshowFromDirection
	) => {
		if (hasPreload) {
			setPreloadedItems([getNextItemToPreload(index, direction)])
		}
	}

	function getNextItemToPreload(
		currentIndex: number,
		fromDirection: SlideshowFromDirection
	): JSX.Element {
		return children[getNewIndexFromDirection(currentIndex, fromDirection)]
	}

	function getRtlDirection(
		direction: SlideshowFromDirection
	): SlideshowFromDirection {
		let rtlDirection: SlideshowFromDirection = direction

		if (isRtl) {
			if (direction === SlideshowFromDirection.LEFT) {
				rtlDirection = SlideshowFromDirection.RIGHT
			} else {
				rtlDirection = SlideshowFromDirection.LEFT
			}
		}

		return rtlDirection
	}

	function isOutOfBounds({
		hasLoop,
		isFirstItemActive,
		isLastItemActive,
		newOffset,
	}: {
		hasLoop: boolean
		isFirstItemActive: boolean
		isLastItemActive: boolean
		newOffset: number
	}) {
		newOffset = newOffset * (isRtl ? -1 : 1)
		const isMovingForward = newOffset > 0
		const isMovingBackward = newOffset < 0
		const isAtBoundary =
			(isFirstItemActive && isMovingForward) ||
			(isLastItemActive && isMovingBackward)

		return !hasLoop && isAtBoundary
	}

	function loopItems(toPreviousSlide: boolean): void {
		const parentElement = slideshowRef.current

		if (!parentElement) {
			return
		}

		if (toPreviousSlide) {
			// Move last element to first position
			const lastChild = parentElement.lastElementChild
			if (lastChild) {
				parentElement.insertBefore(lastChild, parentElement.firstElementChild)
			}
		} else {
			// Move first element to last position
			const firstChild = parentElement.firstElementChild

			if (firstChild) {
				parentElement.appendChild(firstChild)
			}
		}
	}

	function handleSlide(toPreviousSlide: boolean): void {
		const offsetToAdd = toPreviousSlide
			? itemWidthRef.current
			: -itemWidthRef.current
		const newOffset =
			beforeSlideOffsetRef.current + (isRtl ? -offsetToAdd : offsetToAdd)

		beforeSlideOffsetRef.current = newOffset

		updateStyles({
			offset: newOffset,
			transitionDuration: TRANSITION_DURATION,
		})
	}

	function handleLoop(
		fromDirection: SlideshowFromDirection,
		shouldChangeSlide = false
	): void {
		const toPreviousSlide = fromDirection === SlideshowFromDirection.LEFT

		// If the loop is for the previous item, we set the active item to 1 (previously 0)
		// If the loop is for the next item, we set the active item to length - 2 (previously length -1)
		activeItemIndexRef.current = toPreviousSlide
			? 1
			: children.length - SECOND_LAST_INDEX

		// We update the offset and styles since we are no longer in item 0 anymore
		const multiplier = isRtl ? 1 : -1
		const adjustedItemWidth = !isRtl
			? itemWidthRef.current
			: -itemWidthRef.current
		const newOffset = toPreviousSlide
			? itemWidthRef.current * multiplier
			: beforeSlideOffsetRef.current + adjustedItemWidth

		beforeSlideOffsetRef.current = newOffset

		updateStyles({ offset: newOffset })

		if (shouldChangeSlide) {
			// We have to delay this in order to let the previous loop styles effect make effect and be processed by the browser
			setTimeout(() => {
				handleSlide(toPreviousSlide)
			})
		}

		loopItems(toPreviousSlide)
	}

	function restoreSlide(): void {
		updateStyles({
			offset: beforeSlideOffsetRef.current,
			transitionDuration: TRANSITION_DURATION,
		})
	}

	function updateStyles({
		offset,
		transitionDuration,
	}: UpdateSlideshowStylesProps): void {
		if (!slideshowRef.current) {
			return
		}
		if (offset || offset === 0) {
			slideshowRef.current.style.setProperty(
				'--slideshow-offset',
				`${offset}px`
			)
		}
		if (transitionDuration || transitionDuration === 0) {
			slideshowRef.current.style.setProperty(
				'--slideshow-transition-duration',
				`${transitionDuration}ms`
			)
		}
	}

	function getCurrentOffset(): number {
		return parseInt(
			slideshowRef.current?.style.getPropertyValue('--slideshow-offset') ??
				'0px',
			10
		)
	}

	function getSlideshowWidth(): number {
		return slideshowRef.current?.getBoundingClientRect().width ?? 0
	}

	function onSlideshowResize(): void {
		const slideshowWidth = getSlideshowWidth()

		if (
			getCurrentOffset() !== 0 &&
			slideshowWidth !== 0 &&
			slideshowRef.current
		) {
			const offset =
				activeItemIndexRef.current *
				-slideshowRef.current.getBoundingClientRect().width
			updateStyles({
				offset: offset * (isRtl ? -1 : 1),
				transitionDuration: 0,
			})
		}

		itemWidthRef.current = slideshowWidth
		beforeSlideOffsetRef.current = getCurrentOffset()
	}

	function onTransitionEnd(): void {
		updateStyles({ transitionDuration: 0 })
	}

	useEffect(() => {
		itemWidthRef.current = getSlideshowWidth()
		// We have to observe the Slideshow resize in order to adjust the offset
		const observer = new ResizeObserver(onSlideshowResize)
		if (slideshowRef.current) {
			observer.observe(slideshowRef.current)
		}
		return () => {
			observer.disconnect()
		}
	}, [])

	return {
		getNewIndexFromDirection,
		getNextItemToPreload,
		getRtlDirection,
		getSlideshowWidth,
		handleLoop,
		handleSlide,
		restoreSlide,
		updateStyles,
		isOutOfBounds,
		onTransitionEnd,
		preloadNeighborItems,
	}
}
