'use client'

import { type MutableRefObject, useState } from 'react'

import { SlideshowFromDirection } from '../../types/Slideshow'

type UseSlideshowBulletsProps = {
	activeItemIndexRef: MutableRefObject<number>
	getNewIndexFromDirection: (
		currentIndex: number,
		fromDirection: SlideshowFromDirection
	) => number
}

export const useSlideshowBullets = ({
	activeItemIndexRef,
	getNewIndexFromDirection,
}: UseSlideshowBulletsProps) => {
	/* Bullet management in order to track the current item index */
	const [bulletIndex, setBulletIndex] = useState(0)

	function updateBulletsByDirection(
		fromDirection: SlideshowFromDirection
	): number {
		const toPreviousSlide = fromDirection === SlideshowFromDirection.LEFT

		activeItemIndexRef.current =
			activeItemIndexRef.current + (toPreviousSlide ? -1 : 1)

		const newBulletIndex = getNewIndexFromDirection(bulletIndex, fromDirection)

		setBulletIndex(newBulletIndex)

		return newBulletIndex
	}

	return { bulletIndex, updateBulletsByDirection }
}
