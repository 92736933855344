import { IconNextS } from 'icons/components/IconNextS'
import { IconPrevSmall } from 'icons/components/IconPrevSmall'
import type { MouseEvent } from 'react'
import { cx } from 'utils/cx'

import { useSlideshowArrows } from '../hooks/useSlideshowArrows/useSlideshowArrows'
import { SlideshowFromDirection } from '../types/Slideshow'

import styles from '../Slideshow.module.scss'

interface SlideshowArrowsProps {
	readonly bulletIndex: number
	readonly hasLoop: boolean
	readonly labels: { previous: string; next: string }
	readonly lastBulletIndex: number
	readonly onArrowClick: (
		event: MouseEvent<HTMLButtonElement>,
		direction: SlideshowFromDirection
	) => void
	readonly onNavigationMouseEnter: (
		fromDirection: SlideshowFromDirection
	) => void
	readonly onSwipe?: () => void
	readonly dataTestId?: string
	readonly isTouchable?: boolean
}

export const SlideshowArrows = ({
	bulletIndex,
	hasLoop,
	labels,
	lastBulletIndex,
	onArrowClick,
	onNavigationMouseEnter,
	onSwipe,
	dataTestId,
	isTouchable,
}: SlideshowArrowsProps) => {
	const {
		handleClick,
		handleMouseEnter,
		isArrowNextDisabled,
		isArrowPreviousDisabled,
	} = useSlideshowArrows({
		bulletIndex,
		hasLoop,
		isTouchable,
		lastBulletIndex,
		onArrowClick,
		onNavigationMouseEnter,
		onSwipe,
	})

	return (
		<>
			<button
				type='button'
				className={cx(styles.arrow, styles.prevArrow)}
				data-testid={dataTestId && `${dataTestId}.previewItemButton`}
				onClick={(e) => handleClick(e, SlideshowFromDirection.LEFT)}
				onMouseEnter={() => handleMouseEnter(SlideshowFromDirection.RIGHT)}
				aria-label={labels.previous}
				disabled={isArrowPreviousDisabled}
			>
				<IconPrevSmall className={styles.prevIcon} />
			</button>
			<button
				type='button'
				className={cx(styles.arrow, styles.nextArrow)}
				data-testid={dataTestId && `${dataTestId}.nextItemButton`}
				onClick={(e) => handleClick(e, SlideshowFromDirection.RIGHT)}
				onMouseEnter={() => handleMouseEnter(SlideshowFromDirection.RIGHT)}
				aria-label={labels.next}
				disabled={isArrowNextDisabled}
			>
				<IconNextS className={styles.nextIcon} />
			</button>
		</>
	)
}
